const messages = {
  es: {
    cancel: 'Cancelar',
    send: 'Enviar',
    sending: 'Enviando...',
    name: 'Nombre',
    okey: 'Bueno',
    event_password: 'Contraseña del evento',
    registration: 'registro',
    city: 'Ciudad',
    'country/region': 'País / Región',
    'zip/postal_code': 'Zip / Código postal',
    'state/province': 'Estado / Provincia',
    phone: 'teléfono',
    address: 'Dirección',
    speciality: 'Especialidad',
    organization: 'Organización',
    job_title: 'Título Profesional',
    page_language: 'Idioma de la página',
    type_your_question: 'Escriba su pregunta',
    this_event_is_not_started: 'Este evento aún no ha comenzado.',
    this_event_completed: 'Este evento ha concluido',
    registration_required:
      'Se requiere inscripción para este evento. Redirigiendo a la página de registro.',
  },
};

export default messages;
