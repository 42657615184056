const messages = {
  de: {
    cancel: 'Stornieren',
    send: 'Senden',
    sending: 'Senden...',
    name: 'Name',
    okey: 'Okey',
    registration: 'Anmeldung',
    event_password: 'Veranstaltung Passwort',
    last_name: 'Nachname',
    city: 'Stadt',
    'country/region': 'Land / Region',
    'zip/postal_code': 'Postleitzahl',
    'state/province': 'Bundesland / Provinz',
    phone: 'Telefon',
    address: 'Adresse',
    speciality: 'Spezialität',
    organization: 'Organisation',
    job_title: 'Stellenbezeichnung',
    page_language: 'Website-Sprache',
    type_your_question: 'Schreibe deine Frage',
    this_event_is_not_started: 'Diese Veranstaltung hat noch nicht begonnen.',
    this_event_completed: 'Diese Veranstaltung wurde abgeschlossen.',
    registration_required:
      'Für diese Veranstaltung ist eine Anmeldung erforderlich.',
  },
};

export default messages;
